@font-face {
  font-family: "Paragraph";   /*Can be any text*/
  src: local("Paragraph"),
    url("./fonts/Faustina-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Header";   /*Can be any text*/
  src: local("Header"),
    url("./fonts/NixieOne-Regular.ttf") format("truetype");
}